import './bootstrap';
import { Modal } from 'bootstrap'

import $ from 'jquery';
window.$ = window.jQuery = $;
import 'jquery-ui/ui/effect.js';

$(document).ready(function($) {

    $('#btnMenuTop').bind('click', function (event) {
        document.getElementById("divMenuSmallTopExtend").classList.remove("d-none");
        document.getElementById("btnMenuTop").classList.add("d-none");
        
    });
    $('#btnMenuTopClose').bind('click', function (event) {
        document.getElementById("divMenuSmallTopExtend").classList.add("d-none");
        document.getElementById("btnMenuTop").classList.remove("d-none");
    });
    
    let url = window.location.href;
    // console.log("url", url);
    $('ul li a').each(function() {
      if (url.includes('services')) {
        $('.menu-catalogue').addClass('active');
      }
      if (this.href === url) {
        $(this).addClass('active');
      }
    });

   /*
   * scroll to the top
   */
   window.onscroll = function() {affBtnScrollTop()};
    
   function affBtnScrollTop() {
       if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
           document.getElementById("btnScrollTop").style.display = "block";
       } else {
           document.getElementById("btnScrollTop").style.display = "none";
       }
   }
   
   $('#btnScrollTop').bind('click', function (event) {
       $('html, body').stop().animate({
           scrollTop: 0
       }, 1500, 'easeInOutExpo');
       event.preventDefault();
   });

});